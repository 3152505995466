.Navigation {
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: var(--main-color--white);
}

.Navigation ul {
    margin: 0;
    padding: var(--main-spacing--small);
    text-align: center;
    box-shadow: var(--main-color--grey) 0px 0px 5px;
}

.Navigation ul li {
    display: inline-block;
    padding: 0 var(--main-spacing--medium) 0 0;
}

.Navigation .Navigation--link {
    border: none;
    color: #000000;
    background-color: transparent;
    font-family: var(--main-font-family);
    font-size: 100%;
    padding: var(--main-spacing--xxsmall);
    text-decoration: none;
}

.Navigation .Navigation--link:hover {
    cursor: pointer;
}

.Navigation .Navigation--link:hover,
.Navigation .Navigation--link:hover,
.Navigation .Navigation--link:focus,
.Navigation .Navigation--link:focus {
    color: var(--main-color--grey-xlight);
    background-color: var(--main-color--grey);
}

.Navigation .Navigation--title {
    font-family: "Oleo Script", Helvetica, sans-serif;
    font-size: 150%;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.4));
    position: relative;
    top: 1px;
    color: black;
    text-decoration: none;
}
