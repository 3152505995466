.CreateWord-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.CreateWord {
    color: var(--main-color--grey);
    opacity: 1;
    transition: all 0.2s ease-in-out;
    text-align: center;
    box-shadow: var(--main-color--grey) 0px 0px 5px;
    padding: var(--main-spacing--xlarge) var(--main-spacing--xlarge)
        var(--main-spacing--medium);
    background-color: var(--main-color--white);
    border-radius: var(--main-spacing--xsmall);
}

.CreateWord--empty {
    opacity: 0;
}

.CreateWord input {
    font-size: var(--main-text-size--large);
    padding: var(--main-spacing--medium);
    border-radius: var(--main-spacing--xsmall);
    border: var(--main-spacing--xxsmall) solid var(--main-color--grey-xlight);
    transition: border 0.2s ease-out;
    margin: 0;
}

.CreateWord input:focus {
    outline: none;
    border-color: var(--main-color--grey);
}

.CreateWord input::placeholder {
    color: #ddd;
}

.CreateWord input {
    width: 50vmax;
}
