.Settings {
    padding: var(--main-spacing--medium);
    min-width: 35vmax;
    max-width: 75vmin;
}

.Settings .title {
    text-align: center;
}

.Settings .icon {
    width: 2em;
}

.Settings .blurred {
    filter: blur(2px);
    transition: 0.2s filter linear;
}
