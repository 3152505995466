.Card {
    color: var(--main-color--grey);
    padding: var(--main-spacing--xlarge) var(--main-spacing--xlarge)
        var(--main-spacing--xlarge);
    margin-top: var(--main-spacing--large);
    min-width: 50vw;
    text-align: center;
    border-radius: var(--main-spacing--xsmall);
    opacity: 1;
    transition: all 0.2s ease-in-out;
    position: relative;
    background-color: white;
    box-shadow: var(--main-color--grey) 0px 0px 5px;
}

.Card--loading {
    opacity: 0;
}

.Card h2 {
    font-size: 6em;
    font-family: "Oleo Script", Helvetica, sans-serif;
    text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1), 7px 7px 0px rgba(0, 0, 0, 0.05);
}

.Card h2 button {
    position: relative;
    top: -2vmin;
    right: -2vmin;
}

.Card .icon {
    height: 10vmin;
}

.Card .icon--small {
    height: 4vmin;
}

.Card button {
    background: none;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
}
.Card button:hover,
.Card button:focus {
    opacity: 1;
}

.Card .blurrable {
    transition: filter 0.2s ease-in-out;
}

.Card .blurred {
    filter: blur(1rem);
}

.Card .helpers {
    position: absolute;
    top: calc(var(--main-spacing--small) * -1);
    right: 0;
    list-style: none;
    padding: var(--main-spacing--medium);
}
.Card .helpers li {
    display: inline-block;
    margin-left: var(--main-spacing--xsmall);
}

.Card .writedown {
    position: absolute;
    top: calc(var(--main-spacing--small) * 1);
    left: 0;
    width: 100%;
    text-align: center;
    padding: var(--main-spacing--xsmall) 0;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: var(--main-text-size--large);
}

@keyframes wobble {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(40deg);
    }
    50% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(30deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.Card .writedown img {
    height: 1.5em;
    margin-left: 0.5em;
    vertical-align: text-bottom;
    animation: wobble 0.6s linear 2 forwards;
    animation-delay: 0.3s;
}
