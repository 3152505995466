.Home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: var(--main-color--background);
}

.Home-container--with-texture {
    background: linear-gradient(
                90deg,
                var(--main-color--background) 18px,
                transparent 1%
            )
            center,
        linear-gradient(var(--main-color--background) 18px, transparent 1%)
            center,
        var(--main-color--grey-xlight);
    background-size: 20px 20px;
}

.Home {
    background-color: transparent;
}

.Home .photo {
    width: 15vmin;
    max-width: 100px;
    border-radius: 2vmin;
}

.Home .illustration {
    max-width: 50vmin;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
}

.Home .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 80vmax;
    padding: 0 5vmax 5vmax 5vmax;

    align-items: center;
    justify-content: center;
}

.Home .row-reverse {
    flex-direction: row-reverse;
}

.Home .row .col {
    width: calc(50% - 2 * var(--main-spacing--medium));
    padding: 0 var(--main-spacing--medium);
}

@media (max-width: 1000px) {
    .Home .row {
        display: block;
        padding: 2vmax;
    }
    .Home .row .col {
        width: calc(100% - 2 * var(--main-spacing--medium));
    }
}

.Home .paper {
    border-radius: var(--main-spacing--medium);
}

.Home .paper-cyan {
    background-color: var(--main-color--cyan-xlight);
}

.Home .paper-orange {
    background-color: var(--main-color--orange-xlight);
}

.Home .paper h3 {
    margin-top: 0;
}

.Home .paper ul {
    padding: var(--main-spacing--medium) var(--main-spacing--medium) 0
        var(--main-spacing--medium);
    margin: 0;
    list-style: none;
}

.Home .paper ul li:before {
    content: "→";
    margin-left: -1.5em;
    margin-right: 1em;
}

.Home .paper .white-outline {
    text-shadow: 0 5px 5px #fff, 0 -5px 5px #fff, 5px 0 5px #fff,
        -5px 0 5px #fff;
}
