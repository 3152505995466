.WebApiStatus {
    position: absolute;
    width: 50vmin;
    top: 1vmin;
    left: 50%;
    margin-left: -25vmin;
    text-align: center;
    padding: var(--main-spacing--medium);
    background-color: indianred;
    color: var(--main-color--white);
    border-radius: var(--main-spacing--small);
    opacity: 1;
    transition: ease-in-out all 0.1s;
}

.WebApiStatus--empty {
    opacity: 0;
}
