.Score-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.Score p {
    font-size: var(--main-text-size--xlarge);
    text-shadow: 0 0 50px var(--main-color--white);
}

.Score .progress {
    width: 20vmax;
    height: 10px;
    background-color: var(--main-color--white);
    border-radius: var(--main-spacing--small);
}

.Score .progress div {
    background: linear-gradient(to right, #642b73, #c6426e);
    border-radius: var(--main-spacing--small);
}
