:root {
    --main-spacing--xxsmall: 0.5vmin;
    --main-spacing--xsmall: 1vmin;
    --main-spacing--small: 2vmin;
    --main-spacing--medium: 3vmin;
    --main-spacing--large: 5vmin;
    --main-spacing--xlarge: 10vmin;

    --main-text-size--xsmall: 0.25em;
    --main-text-size--small: 0.5em;
    --main-text-size--medium: 1em;
    --main-text-size--large: 1.5em;
    --main-text-size--xlarge: 3em;
    --main-text-size--xxlarge: 5em;

    --main-font-family: Helvetica, "Georgia", Times, serif;

    --main-color--background: #ffffff;
    --main-color--grey: #2b2b2b;
    --main-color--grey-xlight: #dddddd;
    --main-color--white: #ffffff;
    --main-color--orange: rgb(224, 160, 64);
    --main-color--orange-light: rgb(239, 207, 159);
    --main-color--orange-xlight: rgb(247, 241, 233);
    --main-color--cyan: rgb(64, 195, 224);
    --main-color--cyan-light: rgb(158, 224, 239);
    --main-color--cyan-xlight: rgb(235, 245, 248);
    --main-color--purple: rgb(183, 64, 224);
    --main-color--purple-light: rgb(218, 159, 239);
    --main-color--purple-xlight: rgb(242, 233, 237);
}

body {
    margin: 0 0 var(--main-spacing--large) 0;
    font-family: var(--main-font-family);
    background-color: var(--main-color--background);
    font-size: 100%;
    line-height: 1.4;

    background: linear-gradient(
        90deg,
        var(--main-color--cyan),
        var(--main-color--purple)
    );
    background-size: 100vmax 100vmax;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

h1 {
    font-size: var(--main-text-size--xxlarge);
    font-family: "Oleo Script", Helvetica, sans-serif;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
}
h2 {
    font-size: var(--main-text-size--xlarge);
}
h3 {
    font-size: var(--main-text-size--large);
}
p {
    font-size: var(--main-text-size--medium);
}

h1,
h2,
h3,
p {
    margin: 0 0 var(--main-spacing--medium) 0;
}

.mrm {
    margin-right: var(--main-spacing--medium);
}
.mlm {
    margin-left: var(--main-spacing--medium);
}
.mtn {
    margin-top: 0;
}

.pal {
    padding: var(--main-spacing--large);
}
.pam {
    padding: var(--main-spacing--medium);
}
.ptl {
    padding-top: var(--main-spacing--large);
}
.prs {
    padding-right: var(--main-spacing--small);
}
.prm {
    padding-right: var(--main-spacing--medium);
}
.pbl {
    padding-bottom: var(--main-spacing--large);
}
.pbm {
    padding-bottom: var(--main-spacing--medium);
}
.pbs {
    padding-bottom: var(--main-spacing--small);
}
.ptn {
    padding-top: 0 !important;
}

.tac {
    text-align: center;
}
.tal {
    text-align: left;
}
.tb {
    font-weight: bold !important;
}
