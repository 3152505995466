.WordsList-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.WordsList {
    color: var(--main-color--grey);
    padding: var(--main-spacing--xlarge) var(--main-spacing--xlarge)
        var(--main-spacing--xlarge);
    margin-top: var(--main-spacing--xlarge);
    min-width: 30vw;
    border-radius: var(--main-spacing--xsmall);
    opacity: 1;
    transition: all 0.2s ease-in-out;
    position: relative;
    background-color: var(--main-color--white);
    box-shadow: var(--main-color--grey) 0px 0px 5px;
}

.WordsList .ptitle {
    padding-left: 16px;
}

.WordsList .icon {
    height: 10vmin;
}
